import React, { useContext } from 'react';
import { number, shape, string, func } from 'prop-types';

import { Card, CardTitle, Typography } from '@one-thd/sui-atomic-components';

import { RecentlyViewed } from './RecentlyViewed';
import { TopSavingsForYou } from './TopSavingsForYou';
import { BuyItAgain } from './BuyItAgain';
import { TopCategoriesForYou } from './TopCategoriesForYou';
import { TrendingInYourNeighborhood } from './TrendingInYourNeighborhood';
/* eslint-disable max-len */
import { TodaysRecommendationsForYou } from './TodaysRecommendationsForYou';
import { SponsoredProducts } from './SponsoredProducts';
import { ArticleGuides } from './ArticleGuides';
import { PriceDrop } from './PriceDrop';
import { HomepageContext } from '../context/HomepageContext';

const REQUEST_KEY = 'G4xP51k3Aw8tOEvGUKRHbmYW8cM9XrXX';

/**
 * These are our global "_variables.scss" related to screen size breakpoints.
 * $screen-xs: 640px;
 * $screen-mini: 768px;
 * $screen-sm: 1024px;
 * $screen-md: 1280px;
 * $screen-lg: 1440px;
 *
 * In the object below the key is the size it is less than and the value is the number of `slidesPer`
 * for Recs Carousels (they have their own way of handling this; `slidesPer` is not used in other carousels)
 */
const SLIDES_PER_KEY = {
  minWidthMedium: {
    4: 2,
    6: 3,
    8: 4,
    12: 5,
  },
  minWidthLarge: {
    4: 2,
    6: 4,
    8: 5,
    12: 7,
  },
};

const SLIDES_PER_KEY_ARTICLE_GUIDES = {
  minWidthMedium: {
    4: 1,
    6: 1,
    8: 2,
    12: 2,
  },
  minWidthLarge: {
    4: 1,
    6: 2,
    8: 2,
    12: 3,
  },
};

function getSlidesPer({ renderWidths, slidesPerKey, windowWidth }) {
  return windowWidth === 'maxWidthMedium'
    ? null
    : slidesPerKey[windowWidth][+renderWidths[windowWidth]];
}

export function Recommendations({ customRenderId, renderWidths, id, hideComponentCallback }) {
  const {
    store,
    customerType,
    guestUserId,
    windowWidth,
    mcvisID,
    userAuthStatus,
    storefront
  } = useContext(HomepageContext);

  const brandTitleMaxLine = 4;

  const slidesPer = getSlidesPer({
    renderWidths,
    slidesPerKey: SLIDES_PER_KEY,
    windowWidth,
  });

  switch (customRenderId) {
  case 'Recently Viewed': {
    return (
      <RecentlyViewed
        id={id}
        brandTitleMaxLine={brandTitleMaxLine}
        hideComponentCallback={hideComponentCallback}
      />
    );
  }

  case 'Top Savings for You': {
    return (
      <TopSavingsForYou store={store} customerType={customerType} id={id} />
    );
  }

  case 'Buy It Again': {
    return (
      <BuyItAgain
        REQUEST_KEY={REQUEST_KEY}
        id={id}
        brandTitleMaxLine={brandTitleMaxLine}
        slidesPer={slidesPer}
        storefront={storefront}
      />
    );
  }

  case 'Top Categories for You': {
    return (
      <TopCategoriesForYou
        id={id}
        REQUEST_KEY={REQUEST_KEY}
        mcvisID={mcvisID}
        slidesPer={slidesPer}
        customerType={customerType}
      />
    );
  }

  case 'Trending In Your Neighborhood': {
    return <TrendingInYourNeighborhood store={store} slidesPer={slidesPer} id={id} />;
  }

  case 'Todays Recommendations for You': {
    return (
      <TodaysRecommendationsForYou
        id={id}
        brandTitleMaxLine={brandTitleMaxLine}
        store={store}
        slidesPer={slidesPer}
      />
    );
  }

  case 'Sponsored Products': {
    return (
      <SponsoredProducts
        userAuthStatus={userAuthStatus}
        customRenderId={customRenderId}
        slidesPer={slidesPer}
      />
    );
  }

  case 'Article Guides': {
    /* eslint-disable no-shadow */
    const slidesPer = getSlidesPer({
      renderWidths,
      slidesPerKey: SLIDES_PER_KEY_ARTICLE_GUIDES,
      windowWidth,
    });

    return (
      <ArticleGuides
        REQUEST_KEY={REQUEST_KEY}
        guestUserId={guestUserId}
        slidesPer={slidesPer}
        customRenderId={customRenderId}
      />
    );
  }

  case 'Price Drop': {
    return (
      <PriceDrop
        REQUEST_KEY={REQUEST_KEY}
        brandTitleMaxLine={brandTitleMaxLine}
        slidesPer={slidesPer}
        id={id}
      />
    );
  }

  default: {
    return (
      <Card>
        <CardTitle
          header={(
            <Typography variant="h3" weight="regular">
              {customRenderId}
            </Typography>
          )}
        />
      </Card>
    );
  }
  }
}

Recommendations.propTypes = {
  id: string,
  customRenderId: string,
  renderWidths: shape({
    minWidthMedium: number,
    minWidthLarge: number,
  }),
  hideComponentCallback: func,
};

Recommendations.defaultProps = {
  id: undefined,
  customRenderId: undefined,
  renderWidths: {},
  hideComponentCallback: () => {},
};
