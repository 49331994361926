/* eslint-disable no-param-reassign */

const updateComponentRenderWidth = (
  component,
) => {
  component.renderWidths = {
    minWidthLarge: component.sizes.minWidthLarge.min,
    minWidthMedium: component.sizes.minWidthMedium.min,
    maxWidthMedium: component.sizes.maxWidthMedium.min
  };

  return component;
};

export const formatLayoutData = ({
  allComponents,
  myComponents,
  componentOptions = {},
}) => {
  if (
    !allComponents
    || !myComponents
  ) {
    return null;
  }

  const addComponentToGroup = (formattedComponents, component) => {
    if (component.theme) {
      formattedComponents.themed.push(component);
      return formattedComponents;
    }
    formattedComponents.default.push(component);
    return formattedComponents;
  };

  const formattedComponents = allComponents.filter(
    ({ __typename, customRenderId }) => {
      // prefer customRenderId to __typename, fallback to __typename
      return !!componentOptions[customRenderId] || !!myComponents[__typename];
    },
  ).reduce((acc, component) => {
    const { customRenderId } = component;
    if (!customRenderId || !componentOptions[customRenderId]) {
      updateComponentRenderWidth(component);
      return addComponentToGroup(acc, component);
    }

    const { sizes } = componentOptions[customRenderId];

    // Some components may not have the maxWidthMedium size. This will set that size for those components.
    if (sizes) {
      component.sizes = sizes;
      if (!component.sizes.maxWidthMedium) {
        component.sizes.maxWidthMedium = { min: 12, max: 12 };
      }
    }

    updateComponentRenderWidth(component);
    return addComponentToGroup(acc, component);
  }, { themed: [], default: [] });

  return formattedComponents;
};